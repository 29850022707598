import React from 'react'

import SingersLogo from '../../assets/logo/logoTransparent.png'


export default function Logo() {
    return (
        <div id="logoContainer">
            <img className="logoImage" src={SingersLogo} />
        </div>
    )
}
