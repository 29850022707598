
import React from 'react'
import ImageGallery from 'react-image-gallery';

const images = [
    {
      original: '/picture/gallery/1.jpg',
      originalHeight: '500px'
    },
    {
      original: '/picture/gallery/2.jpg',
      originalHeight: '500px'
    },
    {
      original: '/picture/gallery/3.jpg',
      originalHeight: '500px'
    },
    {
      original: '/picture/gallery/4.jpg',
      originalHeight: '500px'
    },
    {
      original: '/picture/gallery/5.jpg',
      originalHeight: '500px'
    },
    {
      original: '/picture/gallery/6.jpg',
      originalHeight: '500px'
    },
    {
      original: '/picture/gallery/7.jpg',
      originalHeight: '500px'
    },
    {
      original: '/picture/gallery/8.jpg',
      originalHeight: '500px'
    },
    {
      original: '/picture/gallery/9.jpg',
      originalHeight: '500px'
    },
    {
      original: '/picture/gallery/10.jpg',
      originalHeight: '500px'
    },
  ];

export default function GallerySection() {
    return (
        <section id="gallerySection">
            <div className="section">
                <div className="sectionCol jcc aic tac mb15">
                  
                    <h1>Galeria</h1>
                    <p>Nasze rameny przygotowujemy z pasją ze świeżych tylko wegańskich składników</p>

                </div>
                <div className="sectionCol">
                    <ImageGallery items={images} showNav={false} showBullets={true} autoPlay={true} slideDuration={400} />
                </div>
            </div>
        </section>
    )
}
